// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableMainContainer table thead tr th:first-child, .tableMainContainer table tbody tr td:first-child {
    max-width: 60px;
  }

  .tableMainContainer table thead tr th:last-child, .tableMainContainer table tbody tr td:last-child {
    max-width: 90px;
  }

  .tableIdContainer table thead tr th:first-child, .tableIdContainer table tbody tr td:first-child {
    max-width: 60px;
  }

  .tableIdContainer table thead tr th:last-child, .tableIdContainer table tbody tr td:last-child {
    max-width: 100px;
    margin-right: 300px;
  }`, "",{"version":3,"sources":["webpack://./components/Table/table.css"],"names":[],"mappings":"AAAA;IACI,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".tableMainContainer table thead tr th:first-child, .tableMainContainer table tbody tr td:first-child {\n    max-width: 60px;\n  }\n\n  .tableMainContainer table thead tr th:last-child, .tableMainContainer table tbody tr td:last-child {\n    max-width: 90px;\n  }\n\n  .tableIdContainer table thead tr th:first-child, .tableIdContainer table tbody tr td:first-child {\n    max-width: 60px;\n  }\n\n  .tableIdContainer table thead tr th:last-child, .tableIdContainer table tbody tr td:last-child {\n    max-width: 100px;\n    margin-right: 300px;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
