import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Paper, Typography, Container, Grid } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListComponent from './ImageList'

export const detailsTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
        },
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #999',
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.75)',
    width: '55%',
    margin: '0',
    padding: '20px',
    borderRadius: '10px',
  },
  sectionHeader: {
    fontSize: '1em !important',
  },
  bold: {
    fontWeight: 'bold',
  },
  gridItem: {
    paddingLeft: '15px',
    paddingBottom: '5px',
    paddingTop: '7px !important',
    border: '1px solid #eee',
    // margin: '10px',
  },
  gridContainer: {
    padding: '5px 0 0 5px !important',
  },
  editButton: {
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '4px 10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}))

interface DetailsCardProps {
  content: Record<string, any>
  linkUrl?: any
  handleGoToEdit: (content: Record<string, any>) => void
}
const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
]

export default function DetailsCard({
  content,
  linkUrl,
  handleGoToEdit,
}: DetailsCardProps) {
  const classes = useStyles()
  const hasImages = content?.Images && content.Images.length > 0
  console.log('content', content)
  return (
    <ThemeProvider theme={detailsTheme}>
      <Container
        disableGutters
        component='main'
        maxWidth='xl'
        sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}
      >
        <Paper className={classes.wrapper}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography
              variant='h5'
              component='h1'
              gutterBottom
              className={`${classes.sectionHeader}`}
            >
              {content.title}
            </Typography>
            {content && !content.link && (
              <Typography
                variant='h6'
                component='h6'
                className={classes.editButton}
                onClick={() => handleGoToEdit(content)}
              >
                <span>Edit</span>
              </Typography>
            )}
          </Box>
          {content ? (
            <Grid container spacing={2} className={classes.gridContainer}>
              {Object.entries(content).map(([key, value]) => {
                if (
                  content.hasOwnProperty(key) &&
                  key !== 'title' &&
                  key !== 'id' &&
                  key !== 'strapiId' &&
                  key !== 'Images'
                ) {
                  // Exclude 'title'
                  return (
                    <>
                      <Grid item xs={4} className={`${classes.gridItem}`}>
                        {`${[key]}:`}
                      </Grid>
                      <Grid item xs={8} className={classes.gridItem}>
                        {key === 'link' ? (
                          value ? (
                            <a
                              href={linkUrl}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <Box sx={{ color: 'rgb(25, 118, 210)' }}>
                                {value as string}
                              </Box>
                            </a>
                          ) : (
                            'N/A'
                          )
                        ) : (
                          <Typography>
                            {typeof value === 'object' &&
                            value !== null &&
                            value.User
                              ? value.User.first_name
                              : value || 'N/A'}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )
                }
                return null
              })}
            </Grid>
          ) : (
            <Typography
              variant='body1'
              component='p'
              sx={{
                fontFamily: 'Roboto Helvetica Arial sans-serif',
                lineHeight: '1.5',
                letterSpacing: '0.00938em',
                marginBottom: '0.35em',
                fontWeight: 'bold',
              }}
            >
              Not Available
            </Typography>
          )}
        </Paper>
        {hasImages && (
          <Paper className={classes.wrapper}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 0,
              }}
            >
              <ImageListComponent
                data={content?.Images}
                width={'100%'}
                height={250}
                cols={4}
                rowHeight={164}
              />
            </Box>
          </Paper>
        )}
      </Container>
    </ThemeProvider>
  )
}
