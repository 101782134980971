import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { createFilterOptions } from '@mui/material/Autocomplete';

type OptionType = {
    firstName: string;
    label: string;
    name: string;
    id: string;
};

type PropType = {
    options?: OptionType[];
    value?: string;
    name?: string;
    label?: string;
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: OptionType | null) => void;
    errormsg?: string;
    error?: boolean;
    margin?: any;
    className?: string;
};

const Dropdown: React.FC<PropType> = ({
                                          options = [],
                                          value,
                                          name,
                                          label,
                                          onChange,
                                          errormsg,
                                          error,
                                          margin,
                                          className,
                                          ...props
                                      }) => {
    const selectedOption = options.find((option) => option.id === value) || null;

    const filterOptions = createFilterOptions({
        stringify: (option: OptionType) => option.name,
    });

    return (
        <div>
            <FormControl sx={{ mt: 2, width: '100%' }} error={error} className={className}>
                <Autocomplete
                    options={options}
                    getOptionLabel={(option) => option.name}
                    filterOptions={filterOptions}
                    value={selectedOption}
                    onChange={(event, newValue) => {
                        onChange && onChange(event, newValue);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            sx={{ ml: 0 }}
                            fullWidth
                            error={error}
                            // margin={margin}
                            name={name}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    )}
                />
                {error && <FormHelperText>{errormsg}</FormHelperText>}
            </FormControl>
        </div>
    );
};

export default Dropdown;
