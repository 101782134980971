import React from 'react'
import {
  Avatar,
  Drawer,
  List,
  Stack,
  Toolbar,
  Box,
  Typography,
  Divider,
} from '@mui/material'
import assets from '../../assets'
import colorConfigs from '../../configs/colorConfigs'
import sizeConfigs from '../../configs/sizeConfigs'
import appRoutes from '../../routes/appRoutes'
import SidebarItem from './SidebarItem'
import SidebarItemCollapse from './SidebarItemCollapse'
import { useNavigate } from 'react-router-dom'

const Sidebar = () => {
  const navigate = useNavigate()
  const goToBookingPage = () => {
    navigate(`/bookings`)
  }

  return (
    <Drawer
      variant='permanent'
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sizeConfigs.sidebar.width,
          boxSizing: 'border-box',
          borderRight: '0px',
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
        },
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: '0px' }}>
          <Stack
            sx={{ width: '100%', marginTop: '20px' }}
            direction='row'
            justifyContent='left'
          >
            <Box>
              <img
                src={assets.images.logo1}
                style={{
                  maxWidth: '80%',
                  height: 'auto',
                  marginBottom: '5px',
                }}
                onClick={goToBookingPage}
              />
              <Typography variant='h6' component='div'>
                Guest Admin Portal
              </Typography>
              <Divider
                sx={{
                  backgroundColor: 'white',
                  margin: '20px 0', // Add margin for spacing
                }}
              />
            </Box>
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) =>
          route.sidebarProps ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        )}
      </List>
    </Drawer>
  )
}

export default Sidebar
