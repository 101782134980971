import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
}

interface ModalComponentProps {
  modalContent: React.ReactNode
  open: boolean
  setOpen: (open: boolean) => void
  handleClose: any
}

const ModalComponent: React.FC<ModalComponentProps> = ({
  modalContent,
  open,
  setOpen,
  handleClose,
}) => {
  // const handleClose = () => {
  //   setOpen(false)
  // }

  const handleBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (event.target === event.currentTarget) {
      // Only close the modal if the click target is the backdrop itself (outer space)
      handleClose()
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        hideBackdrop={true}
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
      >
        <Box sx={style}>
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {modalContent}
        </Box>
      </Modal>
    </div>
  )
}

export default ModalComponent
