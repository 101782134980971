import React, { useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'

interface CalendarProps {
  selectedDate: Date | null
  onDateChange: (date: Date | null, name: string) => void
  label: string
  error?: boolean
  errormsg?: string
}

const Calendar: React.FC<CalendarProps> = ({
  selectedDate,
  onDateChange,
  label,
  error,
  errormsg,
}) => {
  const formattedDate = selectedDate ? selectedDate : null

  return (
    <FormControl sx={{ mt: 2, width: '100%' }} error={error}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
          <DatePicker
            label={label}
            views={['year', 'month', 'day']}
            value={formattedDate}
            onChange={onDateChange}
            format='DD MM YYYY' // Custom date format
            renderInput={(props) => <input {...props} />} // Assuming you have imported the input component
          />
        </DemoContainer>
      </LocalizationProvider>
      {error && <FormHelperText>{errormsg}</FormHelperText>}
    </FormControl>
  )
}

export default Calendar
