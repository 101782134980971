import * as React from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'

interface Person {
  id: number
  name: string
}

interface MultipleSelectProps {
  persons: Person[]
  label: string
  width?: string | number
  background?: string
  color?: string
  border?: string
  onSelectPersons: (selectedPersons: Person[]) => void
  selected?: any
  selectedPersons?: any
  setSelectedPersons?: any
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const getStyles = (
  person: Person,
  selectedPersons: readonly Person[],
  theme: Theme
) => ({
  fontWeight: selectedPersons.some((p) => p.id === person.id)
    ? theme.typography.fontWeightMedium
    : theme.typography.fontWeightRegular,
})

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  persons,
  label,
  width,
  background,
  color,
  border,
  onSelectPersons,
  selected, // Initially selected persons
  setSelectedPersons,
}) => {
  const theme = useTheme()
  const handleChange = (event: any) => {
    const selectedPersonIds = event.target.value
    const selectedPersons = persons.filter((person) =>
      selectedPersonIds.includes(person.id)
    )
    setSelectedPersons(selectedPersons)
    onSelectPersons(selectedPersons) // Notify the parent about selection change
  }

  return (
    <div>
      <FormControl sx={{ m: 1, width: width || 300 }}>
        <InputLabel id='demo-multiple-chip-label'>{label}</InputLabel>
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          placeholder={label}
          value={selected.map((person: any) => person.id)} // Use an array of IDs as the value
          onChange={handleChange}
          input={<OutlinedInput id='select-multiple-chip' label={label} />}
          size='medium'
          sx={{
            backgroundColor: background,
            color: color,
            border: border,
          }}
          // renderValue={(selected) => (
          //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          //     {selected.map((person: any) => (
          //       <Chip key={person.id} label={person.name} />
          //     ))}
          //   </Box>
          // )}
          MenuProps={MenuProps}
        >
          {persons.map((person) => (
            <MenuItem
              key={person.id}
              value={person.id} // Use the person's ID as the value
              style={getStyles(person, selected, theme)}
            >
              {person.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Stack direction='row' spacing={1}>
        <Box
          sx={{
            display: 'flex',
            gap: 0.5,
            whiteSpace: 'nowrap',
          }}
        ></Box>
      </Stack>
    </div>
  )
}

export default MultipleSelect
