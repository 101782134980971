import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {demoInstance, instance} from '../../instance/axios'
import {BookingI} from '../../interfaces/bookingInterface'
import {toast} from 'react-toastify'
import {saveAs} from 'file-saver'

interface BookingInterfaceData {
  bookingGettingState: BookingI
  isModalOpen: boolean
  bookingId: any
  modalCloseData: boolean
  propertiesGettingState: any
  usersGettingState: any
  offersGettingState: any
}

const initialState = {
  bookingGettingState: [],
  isLoading: false,
  error: '',
  isModalOpen: false,
  bookingId: 0,
  lifeCycleStage: [],
  primaryOwner: [],
  accountManager: [],
  getChannelList: [],
  propertiesGettingState: [],
  usersGettingState: [],
  offersGettingState: []
} as unknown as BookingInterfaceData[]

export const getBooking = createAsyncThunk(
  'getBooking',
  async (searchValue: any, {dispatch}) => {
    console.log('data', searchValue)
    try {
      const {data} = await instance({
        method: 'GET',
        url: `api/bookings?search=${searchValue.search}&ravenue_manager_id=${searchValue.ravenue_manager_id}&property_id=${searchValue.propertyId}`,
      })
      return data ? data.bookings : []
    } catch (err: any) {
      toast.error(err?.message ? err.message : '')
      return []
    }
  }
)

export const getProperties = createAsyncThunk('getProperties', async () => {
  try {
    const {data} = await demoInstance({
      method: 'GET',
      url: 'api/properties?limit=200',
    })
    return data ? data.properties : []
  } catch (err: any) {
    toast.error(err?.message ? err.message : '')
    return []
  }
})

export const getUsers = createAsyncThunk(
  'getUsers',
  async (_, {rejectWithValue}) => {
    try {
      const response = await demoInstance.get('api/roles/GUEST/pms_users')
      const modifiedResponse = {
        data: {
          ...response?.data,
          pms_users: response?.data?.pms_users?.map((user: any) => ({
            ...user?.User,
            id: user?.id,
            __typename: user?.__typename,
          })),
        },
        loading: response?.data?.loading,
        networkStatus: response?.data?.networkStatus,
        status: response?.status,
        statusText: response?.statusText,
        headers: response?.headers,
        config: response?.config,
        request: response?.request,
      }
      return modifiedResponse
    } catch (err) {
      console.error('Failed to fetch users:', err)
      return rejectWithValue({})
    }
  }
)

export const createBooking = createAsyncThunk(
  'createBooking',
  async (bookingData: any, {dispatch}) => {
    try {
      const resp: any = await instance({
        method: 'POST',
        url: 'api/bookings',
        data: bookingData,
      })
      
      console.log('resp', resp);
      
      if (resp.data.id) {
        sessionStorage.setItem('BookingId', resp.data.id)
        toast.success('Booking Create Successfully')
        return !!resp.data.id
      }
    } catch (err: any) {
      toast.error(
        err?.message
          ? `${err?.response?.data?.message} (${err?.response?.status} error)`
          : ''
      )
      return []
    }
  }
)

export const addAddress = createAsyncThunk(
  'addAddress',
  async (addressData: any) => {
    try {
      const {id, ...newAddressData} = addressData
      const {data} = await instance({
        method: 'POST',
        url: `api/properties/${id}/addresses`,
        data: newAddressData,
      })
      
      toast.success('Address Create Successfully')
      return data ? true : false
    } catch (err: any) {
      toast.error(err?.message ? err.message : '')
      return []
    }
  }
)

export const getBookingId = createAsyncThunk(
  'getBookingId',
  async (id: any, thunkApi) => {
    try {
      const {data} = await instance({
        method: 'GET',
        url: `api/bookings/${id}`,
      })
      
      const bookings = data?.booking
      if (bookings) {
        const ota = bookings.Channel
        const property = bookings.Property
        const userDetails = bookings.GuestUser.User
        const revenueManagerName = bookings.RevenueManager.User.name
        
        bookings.ota = `${ota?.name || ''}`
        bookings.ota_d = `${ota?.id || ''}`
        bookings.propertyName = `${property?.name}`
        bookings.propertyId = `${property?.id}`
        bookings.GuestUser.User = {...userDetails, revenueManagerName}
      }
      
      return bookings || {}
    } catch (err: any) {
      // toast.error(err?.message || '')
      return []
    }
  }
)

export const editBooking = createAsyncThunk(
  'editBooking',
  async (editData: any, thunkApi) => {
    const {id, ...dataWithoutId} = editData
    try {
      const {data} = await instance({
        method: 'PATCH',
        url: `api/bookings/${id}`,
        data: dataWithoutId,
      })
      toast.success('Booking Update Successfully')
      return data ? true : false
    } catch (err: any) {
      toast.error(
        err?.message
          ? `${err?.response?.data?.message} (${err?.response?.status} error)`
          : ''
      )
      return []
    }
  }
)

export const bookingUpload = createAsyncThunk(
  'bookingUpload',
  async (bookingUploadData: any, thunkApi) => {
    try {
      const {data} = await instance({
        method: 'POST',
        url: 'v1/webadmin/propertys/upload',
        data: bookingUploadData,
      })
      toast.success('Property Image Upload Successfully')
      return data ? true : false
    } catch (err) {
      return []
    }
  }
)

export const lifeCycleStages = createAsyncThunk('lifeCycleStages', async () => {
  try {
    const {data} = await instance({
      method: 'GET',
      url: `api/bookings/lifecycleStages`,
    })
    return Array.isArray(data) ? data : []
  } catch (err) {
    return []
  }
})

export const selectPrimaryOwner = createAsyncThunk(
  'selectPrimaryOwner',
  async () => {
    try {
      const {data} = await instance({
        method: 'GET',
        url: `api/roles/${'PROPERTY_OWNER'}/pms_users`,
      })
      const convertedData = data.pms_users.map((pmsUser: any) => ({
        id: pmsUser.id,
        name: `${pmsUser.User.first_name} ${' '} ${pmsUser.User.last_name}`,
      }))
      return Array.isArray(convertedData) ? convertedData : []
    } catch (err) {
      return []
    }
  }
)

export const keyAccountManager = createAsyncThunk(
  'keyAccountManager',
  async () => {
    try {
      const {data} = await demoInstance({
        method: 'GET',
        url: `api/roles/${'REVENUE_MANAGER'}/pms_users`,
      })
      const convertedData = data.pms_users.map((user: any) => ({
        id: user.id,
        name: `${user.User.first_name} ${' '} ${user.User.last_name}`,
      }))
      return Array.isArray(convertedData) ? convertedData : []
    } catch (err) {
      return []
    }
  }
)

export const getChannelList = createAsyncThunk('getChannelList', async () => {
  try {
    const {data} = await demoInstance({
      method: 'GET',
      url: 'api/channels',
    })
    return data ? data.channels : []
  } catch (err: any) {
    toast.error(err?.message ? err.message : '')
    return []
  }
})


export const getOffersList = createAsyncThunk('getOffersList', async () => {
  try {
    const {data} = await instance({
      method: 'GET',
      url: 'api/offers',
    })
    return data ? data : []
  } catch (err: any) {
    toast.error(err?.message ? err.message : '')
    return []
  }
})

export const addOffer = createAsyncThunk(
  'addOffer',
  async (offerData: { bookingId: number; couponId: string; /* other data fields */ }, thunkApi) => {
    const {id, couponId, ...requestData} = offerData;
    try {
      const {data} = await instance({
        method: 'POST',
        url: `api/bookings/${id}/coupon?coupon_id=${couponId}`,
      })
      toast.success('Offer Added Successfully')
      return data ? true : false
    } catch (err) {
      return []
    }
  }
)

export const addChannel = createAsyncThunk(
  'addChannel',
  async (channelData: any, thunkApi) => {
    const {id, ...requestData} = channelData
    try {
      const {data} = await instance({
        method: 'POST',
        url: `api/properties/${id}/channels/multiple`,
        data: requestData,
      })
      toast.success('Channel saved successfully')
      return data ? true : false
    } catch (err) {
      return []
    }
  }
)

export const createInvoices = createAsyncThunk(
  'createInvoices',
  async (invoiceData: any, thunkApi) => {
    const {id, ...requestData} = invoiceData
    try {
      const {data} = await instance({
        method: 'POST',
        url: `api/bookings/${id}/invoices`,
        data: requestData,
      })
      toast.success('Invoice Upload Successfully')
      return data ? true : false
    } catch (err) {
      return []
    }
  }
)

export const downloadBookingReport = createAsyncThunk(
  'downloadBookingReport',
  async (searchValue: any, {dispatch}) => {
    try {
      const {data} = await instance({
        method: 'GET',
        url: `api/bookings/excel/download?search=${searchValue.search}&ravenue_manager_id=${searchValue.revenue_manager_id}&property_id=${searchValue.propertyId}`,
        
        responseType: 'blob', // Add this line to indicate the response type is a blob
      });
      
      // Create a Blob with the response data
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      
      // Use the saveAs function to save the blob as a file
      saveAs(blob, 'booking.xlsx'); // Use appropriate file extension and name
      
      return true;
    } catch (err) {
      console.error('Error downloading booking report:', err);
      return false;
    }
  }
)

const bookingslice = createSlice({
  name: 'Booking Reducer',
  initialState,
  reducers: {
    openModal: (state, id) => {
      state.isModalOpen = true
      state.bookingId = id.payload
    },
    closeModal: (state) => {
      state.isModalOpen = false
    },
  },
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getBooking.fulfilled, (state: any, action: any) => {
      // Add user to the state array
      state.bookingGettingState = action.payload
    }),
      builder.addCase(getOffersList.fulfilled, (state: any, action: any) => {
        state.offersGettingState = action.payload
      }),
      builder.addCase(getProperties.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        state.propertiesGettingState = action.payload
      }),
      builder.addCase(getUsers.fulfilled, (state: any, action: any) => {
        // Add user to the state array
        state.usersGettingState = action.payload
      }),
      builder.addCase(createBooking.pending, (state: any, action: any) => {
        state.isLoading = false
      }),
      builder.addCase(createBooking.fulfilled, (state: any, action: any) => {
        state.isLoading = true
      }),
      builder.addCase(createBooking.rejected, (state: any, action: any) => {
        ;(state.isLoading = false), (state.error = action.payload)
      }),
      builder.addCase(editBooking.fulfilled, (state: any, action: any) => {
        state.isLoading = true
      }),
      builder.addCase(lifeCycleStages.fulfilled, (state: any, action: any) => {
        state.lifeCycleStage = action.payload
      }),
      builder.addCase(
        selectPrimaryOwner.fulfilled,
        (state: any, action: any) => {
          state.primaryOwner = action.payload
        }
      ),
      builder.addCase(
        keyAccountManager.fulfilled,
        (state: any, action: any) => {
          state.accountManager = action.payload
        }
      ),
      builder.addCase(getChannelList.fulfilled, (state: any, action: any) => {
        state.getChannelList = action.payload
      })
  },
})
export const {openModal, closeModal} = bookingslice.actions
export default bookingslice.reducer
