import * as React from 'react'
import { ImageList, ImageListItem } from '@mui/material'

interface Image {
  img: string
  title: string
}

interface ImageListComponentProps {
  data: Image[]
  width: number | string
  height: number | string
  cols: number
  rowHeight: number
}

const ImageListComponent: React.FC<ImageListComponentProps> = ({
  data,
  width,
  height,
  cols,
  rowHeight,
}) => {
  return (
    <ImageList
      sx={{ width, height, margin: 0 }}
      cols={cols}
      rowHeight={rowHeight}
    >
      {data?.map((item) => (
        <ImageListItem sx={{ margin: 0 }} key={item.img}>
          <img
            src={`${item?.img}`}
            srcSet={`${item?.img}`}
            // alt={item?.title}
            loading='lazy'
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

export default ImageListComponent
