import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { instance, demoInstance, webApiInstance } from '../../instance/axios'
import { PropertiesI } from '../../interfaces/porpertyInterface'
import { toast } from 'react-toastify'

interface PropertyInterfaceData {
  propertiesGettingState: PropertiesI
}

const initialState = {
  propertiesGettingState: [],
  propertyDetails: [],
  propertyDetailsFromStrapi: [],
  getRevenueManagerlist: []
} as unknown as PropertyInterfaceData[]

export const getProperties = createAsyncThunk('getProperties', async () => {
  try {
    const { data } = await instance({
      method: 'GET',
      url: '/api/properties',
    })
    console.log('data', data)
    return data ? data : []
  } catch (err: any) {
    toast.error(err?.message ? err.message : '')
    return []
  }
})

export const getPropertiesDetails = createAsyncThunk(
  'getPropertiesDetails',
  async (id: any, thunkApi) => {
    try {
      const { data } = await demoInstance({
        method: 'GET',
        url: `api/properties/${id}`,
      })

      if (data) {
        // Dispatch getPmsPropertiesList with the fetched data
        await thunkApi.dispatch(getPmsPropertiesList(data))
      }

      return data ? data?.properties : []
    } catch (err: any) {
      // Handle error if needed
      throw err
    }
  }
)

const INSIDE_KEYS = {
  BATHROOMS: 'bathrooms',
  BEDROOMS: 'bedrooms',
  BEDS: 'beds',
  GUESTS: 'guests',
}

export const getPmsPropertiesList = createAsyncThunk(
  'getPmsPropertiesList',
  async (data: any, thunkApi) => {
    try {
      const response = await webApiInstance({
        method: 'GET',
        url: `api/properties/strapiList`,
      })

      const strapiList = response?.data?.data?.properties?.data || []

      const isPropertyMatching = strapiList.some(
        (property: any) =>
          data.properties[0].id === property.attributes.property_id
      )

      if (isPropertyMatching) {
        const strapiDetails = await thunkApi.dispatch(
          getStrapiDetailsById(data.properties[0].id)
        )
          console.log('strapiDetails',strapiDetails)
        const { insides, id, name, Images } = strapiDetails?.payload || {}
        const bathrooms = insides.find(
          (item: any) => item.key === INSIDE_KEYS.BATHROOMS
        )?.value
        const bedrooms = insides.find(
          (item: any) => item.key === INSIDE_KEYS.BEDROOMS
        )?.value
        const beds = insides.find(
          (item: any) => item.key === INSIDE_KEYS.BEDS
        )?.value
        const guests = insides.find(
          (item: any) => item.key === INSIDE_KEYS.GUESTS
        )?.value
          const upDatedImages = Images.map((item:any) => {
            return { img : item}
          })
        return {
          title: 'Basic Details',
          id,
          Name: name,
          Bathrooms: bathrooms,
          Bedrooms: bedrooms,
          Beds: beds,
          Guests: guests,
          Images:upDatedImages
        }
      } else {
        return {
          title: 'Property Content not found or not published in Strapi CMS',
          link: 'Click here to Create Content',
        }
      }
    } catch (err) {
      // Handle error if needed
      throw err
    }
  }
)

export const getStrapiDetailsById = createAsyncThunk(
  'getStrapiDetailsById',
  async (id: any, thunkApi) => {
    try {
      const response = await webApiInstance({
        method: 'GET',
        url: `api/properties/${id}`,
      })
      return response ? response?.data : []
    } catch (err: any) {
      // Handle error if needed
      throw err
    }
  }
)
export const getReveueManager = createAsyncThunk('getReveueManager', async () => {
  try {
    const { data } = await demoInstance({
      method: 'GET',
      url: '/api/roles/REVENUE_MANAGER/pms_users',
    });

    const convertedData = data.pms_users.map((pmsUser: any) => ({
      id: pmsUser.id.toString().padStart(3, '0'),
      name: `${pmsUser.User.first_name} ${pmsUser.User.last_name}`,
    }));

    return Array.isArray(convertedData) ? convertedData : [];
  } catch (err: any) {
    toast.error(err?.message ? err.message : '');
    return [];
  }
});



const propertySlice = createSlice({
  name: 'Property Reducer',
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getProperties.fulfilled, (state: any, action: any) => {
      state.propertiesGettingState = action.payload
    }),
      builder.addCase(
        getPropertiesDetails.fulfilled,
        (state: any, action: any) => {
          state.propertyDetails = action.payload
        }
      ),
      builder.addCase(
        getPmsPropertiesList.fulfilled,
        (state: any, action: any) => {
          state.propertyDetailsFromStrapi = action.payload
        }
      ),
      builder.addCase(getReveueManager.fulfilled, (state: any, action: any) => {
        state.getRevenueManagerlist = action.payload
      })
  },
})
export const {} = propertySlice.actions
export default propertySlice.reducer
function demo(arg0: { method: string; url: string }) {
  throw new Error('Function not implemented.')
}
