import * as React from 'react'
import Chip from '@mui/material/Chip'

interface Person {
  id: number
  name: string
}

interface SelectedPersonsChipsProps {
  selectedPersons: Person[]
  selectedPropertyPersons: Person[]
  selectedRevenueManagerPersons: Person[]
  onDelete: (id: number) => void
}

const SelectedPersonsChips: React.FC<SelectedPersonsChipsProps> = ({
  selectedPersons,
  selectedPropertyPersons,
  selectedRevenueManagerPersons,
  onDelete,
}) => {
  const renderBackgroundColor = (person: Person): string => {
    if (
      selectedPropertyPersons.some(
        (selectedPerson) => selectedPerson.id === person.id
      )
    ) {
      return '#f35f0b3b' // Red background for property persons
    } else if (
      selectedRevenueManagerPersons.some(
        (selectedPerson) => selectedPerson.id === person.id
      )
    ) {
      return '#f0b3ea' // Yellow background for revenue manager persons
    }
    return '' // Default background color
  }

  return (
    <div>
      {selectedPersons.map((person: Person) => (
        <Chip
          key={person.id}
          label={person.name}
          onDelete={() => onDelete(person.id)}
          sx={{
            backgroundColor: renderBackgroundColor(person),
            color: '#fff', // You can customize the text color here
            borderRadius: '20px',
            margin: '0.5rem',
          }}
        />
      ))}
    </div>
  )
}

export default SelectedPersonsChips
