import React, { useState, useEffect } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { AppDispatch } from '@/redux/store'

import { Box, Grid, Paper, Typography, Container } from '@mui/material'
import generateConfig, { Config } from '../../components/HelmetConfig'
import { Helmet } from 'react-helmet'
import BreadcrumbsComponent from '../../components/Breadcrums/Breadcrums'
import { getPropertiesDetails } from '../../redux/features/propertySlice'
import DetailsCard from '../../components/DetailsCard'

export const detailsTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
          // backgroundColor:'yellow'
        },
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #999',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    width: '55%',
    margin: '0',
    padding: '20px',
    borderRadius: '10px',
  },
  gridItem: {
    paddingLeft: '15px',
    paddingBottom: '5px',
    paddingTop: '5px',
    border: '1px solid #eee',
    // margin: '10px',
  },
  gridContainer: {
    padding: '16px 0 0 16px !important',
  },

  container: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  heading: {
    // fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  editButton: {
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '4px 10px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  detailsContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  devider: {
    borderColor: '#000',
  },

  bold: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'underline',
    color: 'rgb(25, 118, 210)',
    cursor: 'pointer',
  },
  sectionHeader: {
    fontSize: '1.3rem !important',
  },
  boxWrap: {
    marginTop: '0 !important',
  },
}))

export default function PropertyDetails() {
  const classes = useStyles()
  const dispatch = useDispatch<AppDispatch>()
  const location = useLocation()
  const propertyDetail = useSelector(
    (state: any) => state.property.propertyDetails[0]
  )
  const propertyDetailFromStrapi = useSelector(
    (state: any) => state.property.propertyDetailsFromStrapi
  )

  const [pageTitle, setPageTitle] = useState('Property Details')
  const config: Config = generateConfig(pageTitle)

  useEffect(() => {
    const propertyId: string =
      location?.pathname.split('/').filter(Boolean).slice(-1)[0] || ''

    // Dispatch the async thunk action and pass the propertyId as an argument
    dispatch(getPropertiesDetails(propertyId))
    // dispatch(getPropertyDetailsFromStrapi())
  }, [dispatch, location])

  const handleGoToEdit = (data: any) => {
    const id = parseInt(data.strapiId)
    if (!id) return
    const url =
      process.env.CMS_ADMIN_URL +
      `/admin/content-manager/collectionType/api::property.property/${id}`
    window.open(url, '_blank')
  }

  const propertyName = propertyDetail ? propertyDetail.name : 'N/A'
  return (
    <>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <BreadcrumbsComponent />
      <Typography
        variant='h6'
        component='h3'
        gutterBottom
        sx={{ marginBottom: 2, marginTop: 2, fontWeight: 'bold' }}
      >
        {`Property : ${propertyName}`}
      </Typography>
      <ThemeProvider theme={detailsTheme}>
        <Container
          disableGutters
          component='main'
          maxWidth='xl'
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}
        >
          <DetailsCard
            content={propertyDetailFromStrapi}
            linkUrl={`${process.env.CMS_ADMIN_URL}/admin/content-manager/collectionType/api::property.property?page=1&pageSize=10&sort=name:ASC`}
            handleGoToEdit={handleGoToEdit}
          />
        </Container>
      </ThemeProvider>
      {/* <ThemeProvider theme={detailsTheme}>
        <Container
          disableGutters
          component='main'
          maxWidth='xl'
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start', gap: 2 }}
        >
          <DetailsCard
            content={propertyDetailFromStrapi}
            linkUrl={`${process.env.CMS_ADMIN_URL}/admin/content-manager/collectionType/api::property.property?page=1&pageSize=10&sort=name:ASC`}
            handleGoToEdit={handleGoToEdit}
          />
        </Container>
      </ThemeProvider> */}
    </>
  )
}
