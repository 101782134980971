import React from 'react'
import { Box } from '@mui/material'

import BreadcrumbsComponent from '../Breadcrums/Breadcrums'
import SpreadSheetUrl from '../SpreadSheetUrl/SpreadSheetUrl'

interface HeaderProps {
  spreadSheetUrl: string
  title: string
}

const Header: React.FC<HeaderProps> = ({ spreadSheetUrl, title }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <BreadcrumbsComponent />
      <SpreadSheetUrl spreadSheetUrl={spreadSheetUrl} title={title} />
    </Box>
  )
}

export default Header
