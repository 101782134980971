import * as React from 'react'
import * as ReactDOM from 'react-dom'
import JsonEditor from 'react-json-editor-ui'
import 'react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css'
import ReactJsonSyntaxHighlighter from 'react-json-syntax-highlighter'
import 'react-json-syntax-highlighter/dist/ReactJsonSyntaxHighlighter.css'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { demoInstance } from '../../instance/axios'
import generateConfig, { Config } from '../../components/HelmetConfig'
import { Helmet } from 'react-helmet'

const App = () => {
  const { id } = useParams()
  const [editObject, setEditObject] = React.useState<any>(false)
  const [productConfig, setProductConfig] = React.useState<any>(false)
  const [pageTitle, setPageTitle] = useState('Config Listing')
  const config: Config = generateConfig(pageTitle)

  const updateProductConfig = async () => {
    const x = await demoInstance.put(`/api/configs/${id}`, {
      value: editObject,
    })
    alert()
  }

  useEffect(() => {
    // Fetch data from the API
    demoInstance
      .get(`/api/configs/${id}`)
      .then((response) => {
        // Update the state with the fetched data
        setProductConfig(response.data)
        setEditObject(response.data.value)
        setPageTitle(`${response?.data?.id} - ${response?.data?.key}`)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  return (
    <>
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <div>
        Namespace: {productConfig.namespace} | Key: {productConfig.key}
      </div>
      <Button onClick={() => updateProductConfig()}>Save</Button>
      <hr />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Container
            disableGutters
            component='main'
            maxWidth='xl'
            sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}
          >
            {editObject ? (
              <JsonEditor
                width={450}
                data={editObject}
                onChange={(data) => {
                  setEditObject(data)
                }}
                optionsMap={{
                  color: [
                    { value: 'red', label: 'Red' },
                    { value: 'blue', label: 'Blue' },
                  ],
                }}
              />
            ) : (
              'Loading...'
            )}
          </Container>
        </Grid>
        <Grid item xs={4}>
          <ReactJsonSyntaxHighlighter obj={editObject} />
        </Grid>
      </Grid>
    </>
  )
}
export default App
