import React from 'react'
import { Box, Link, styled } from '@mui/material'

interface SpreadSheetProps {
  spreadSheetUrl: string
  title: string
}

const SpreadsheetLink = styled(Link)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
  textDecoration: 'none',
}))

const SpreadSheetUrl: React.FC<SpreadSheetProps> = ({
  spreadSheetUrl,
  title,
}) => {
  return (
    <Box>
      {spreadSheetUrl ? (
        <SpreadsheetLink
          href={spreadSheetUrl}
          target='_blank'
          rel='noopener'
          style={{ marginRight: '15px', fontWeight: 'bold' }}
        >
          {title}
        </SpreadsheetLink>
      ) : (
        ''
      )}
    </Box>
  )
}

export default SpreadSheetUrl
