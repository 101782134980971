import { CssBaseline } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CellProps } from 'react-table'
import { Page } from '../../components/Page'
import { Table } from '../../components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/redux/store'
import { getProperties } from '../../redux/features/propertySlice'
import { ToastContainer } from 'react-toastify'
import BreadcrumbsComponent from '../../components/Breadcrums/Breadcrums'
import generateConfig, { Config } from '../../components/HelmetConfig'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

const PropertyListing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [pageTitle, setPageTitle] = useState('Property Listing')
  const config: Config = generateConfig(pageTitle)
  const propertyList = useSelector((state: any) => state.property)
  const data = propertyList?.propertiesGettingState
    ? propertyList?.propertiesGettingState
    : []
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getProperties())
  }, [])

  const handleGoToDetails = (id: number) => {
    navigate(`/properties/${id}`)
  }

  const columns = [
    {
      accessor: `id`,
      aggregate: 'id',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value}`,
      Header: 'id'.toUpperCase(),
    },
    {
      Header: 'Property Name',
      accessor: 'name',
      aggregate: 'name',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value}`,
      Cell: (row: any) => (
        <span
          style={{
            textDecoration: 'underline',
            color: '#1976d2',
            cursor: 'pointer',
          }}
          onClick={() => handleGoToDetails(row.row.original.id)} // Add your onClick method here
        >
          {`${row.row.original.name}`}
        </span>
      ),
    },
    {
      Header: 'Address',
      accessor: 'Address',
      aggregate: 'Address',
      Aggregated: ({ cell: { value } }: CellProps<any>) => `${value}`,
      Cell: (row: any) => (
        <span
        //   style={{
        //     textDecoration: 'underline',
        //     color: '#1976d2',
        //     cursor: 'pointer',
        //   }}
        >
          {`${row.row.original.Address?.area} ${row.row.original.Address?.city}`}
        </span>
      ),
    },
  ] as any[]
  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <BreadcrumbsComponent />
      <Page>
        <CssBaseline />
        <Table
          name={'testTable'}
          columns={columns}
          data={data}
          heading={'Properties'}
        />
      </Page>
    </>
  )
}

export default PropertyListing
