import { configureStore } from '@reduxjs/toolkit';
import usersSlice from './features/usersSlice';
import roleSlice from './features/roleSlice';
import bookingslice from './features/BookingSlice';
import authSlice from './features/authSlice';
import loadingReducer from './features/loaderSlice';
import propertySlice from './features/propertySlice';

const rootReducer = {
  users: usersSlice,
  role: roleSlice,
  book: bookingslice,
  auth: authSlice,
  loader: loadingReducer,
  property: propertySlice
};

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
