import { Button, CssBaseline, Box, Link } from '@mui/material'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { CellProps } from 'react-table'
import moment from 'moment'
import { Page } from '../../components/Page'
import { Table } from '../../components/Table'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Create'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserI } from '../../interfaces/userInterface'
import { AppDispatch } from '@/redux/store'
import {
  getBooking,
  downloadBookingReport,
} from '../../redux/features/BookingSlice'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { ToastContainer } from 'react-toastify'
import generateConfig, { Config } from '../../components/HelmetConfig'
import { Helmet } from 'react-helmet'
import Header from '../../components/Header/Header'
import Title from '../../components/Title/Title'
import SearchInput from '../../components/SearchInput/SearchInput'
import SingleInputDateRangePicker from '../../components/DateRangePicker'
import MultipleSelect from '../../components/MultipleSelect'
import {
  getProperties,
  getReveueManager,
} from '../../redux/features/propertySlice'
import SelectedPersonsChips from '../../components/SelectedChips'

interface Person {
  id: number
  name: string
}

const Bookings: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [pageTitle, setPageTitle] = useState('Guest Listing')
  const config: Config = generateConfig(pageTitle)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedBookingId, setSelectBookingId] = useState('')
  const bookingList = useSelector((state: any) => state.book)
  const propertyList = useSelector((state: any) => state.property)
  const propertyListData = propertyList?.propertiesGettingState
    ? propertyList?.propertiesGettingState
    : []
  const data = bookingList?.bookingGettingState
    ? bookingList?.bookingGettingState
    : []
  const revenueManagerList = useSelector(
    (state: any) => state.property.getRevenueManagerlist
  )
  const [selectedPropertyPersons, setSelectedPropertyPersons] = useState<
    Person[]
  >([])
  const [selectedRevenueManagerPersons, setSelectedRevenueManagerPersons] =
    useState<Person[]>([])
  const [searchValue, setSearchValue] = useState('')

  const handleSelectPropertyPersons = (selectedPersons: Person[]) => {
    setSelectedPropertyPersons(selectedPersons)
    const idsOnlyArray = selectedPersons.map((property) => property.id)
    const propertyIdsString = idsOnlyArray.join(',') // Join the IDs with commas
    const idsOnlyRevenueArray = selectedPersons.map((property) => property.id)
    const revenueIdsString = idsOnlyRevenueArray.join(',') // Join the IDs with commas
    dispatch(
      getBooking({
        search: searchValue,
        propertyId: propertyIdsString,
        ravenue_manager_id: revenueIdsString,
      })
    )
  }

  const handleSelectRevenueManagerPersons = (selectedPersons: Person[]) => {
    setSelectedRevenueManagerPersons(selectedPersons)
    const idsOnlyRevenueArray = selectedPersons.map((property) => property.id)
    const revenueIdsString = idsOnlyRevenueArray.join(',') // Join the IDs with commas
    const idsOnlyArray = selectedPersons.map((property) => property.id)
    const propertyIdsString = idsOnlyArray.join(',') // Join the IDs with commas
    dispatch(
      getBooking({
        search: searchValue,
        propertyId: propertyIdsString,
        ravenue_manager_id: revenueIdsString,
      })
    )
  }

  const navigate = useNavigate()
  const addBookings = useCallback(
    (id: any) => () => {
      navigate(`/bookings/${id}`)
    },
    []
  )

  const editBooking = (data: any) => {
    console.log('editBooking');
    navigate(`/bookings/${data}`, { state: { data } })
  }

  useEffect(() => {
    sessionStorage.removeItem('BookingId')

    const idsOnlyArray = selectedPropertyPersons.map((property) => property.id)
    const propertyIdsString = idsOnlyArray.join(',')
    const idsOnlyArrayOfRevenueManager = selectedRevenueManagerPersons.map(
      (property) => property.id
    )
    const revenueIdsString = idsOnlyArrayOfRevenueManager.join(',')
    dispatch(
      getBooking({
        search: searchValue,
        ravenue_manager_id: revenueIdsString,
        propertyId: propertyIdsString,
      })
    )

    dispatch(getProperties())
    dispatch(getReveueManager())
  }, [selectedPropertyPersons, searchValue, selectedRevenueManagerPersons])

  const handleBookingDetails = (id: any) => {
    const url = `/bookings/${id}/details`
    window.open(url, '_blank')
  }

  const handleToggleModal = (userId: string) => {
    setSelectBookingId((prevUserId) => (prevUserId === userId ? '' : userId))
    setIsOpen((prevOpen) => !prevOpen)
  }

  const modalRef = useRef<HTMLDivElement>(null)

  const handleCloseModal = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsOpen(false)
      setSelectBookingId('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseModal)
    return () => {
      document.removeEventListener('mousedown', handleCloseModal)
    }
  }, [])

  const handleRedirectToProperty = (Property: any) => {
    if (!Property) return ''
    return (
      <Link
        href={
          process.env.PMS_ADMIN_URL + '/properties/' + Property.id + '/details'
        }
        target='_blank'
        style={{
          textDecoration: 'underline',
          color: '#1976d2',
          cursor: 'pointer',
        }}
      >
        {Property.name}
      </Link>
    )
  }

  const downloadToCsv = useCallback(
    () => () => {
    const idsOnlyArray = selectedPropertyPersons.map((property) => property.id)
    const propertyIdsString = idsOnlyArray.join(',')
    const idsOnlyArrayOfRevenueManager = selectedRevenueManagerPersons.map(
        (property) => property.id
    )
    const revenueIdsString = idsOnlyArrayOfRevenueManager.join(',')
      dispatch(
          downloadBookingReport({
            search: searchValue,
            revenue_manager_id: revenueIdsString,
            propertyId: propertyIdsString,
          })
      );
    },
      [selectedPropertyPersons, searchValue, selectedRevenueManagerPersons]
  )

  const handleDelete = (id: any) => {
    // Remove the person from both selectedPropertyPersons and selectedRevenueManagerPersons arrays
    const updatedPropertyPersons = selectedPropertyPersons.filter(
      (person) => person.id !== id
    )
    const updatedRevenueManagerPersons = selectedRevenueManagerPersons.filter(
      (person) => person.id !== id
    )

    // Update the state with the new arrays
    setSelectedPropertyPersons(updatedPropertyPersons)
    setSelectedRevenueManagerPersons(updatedRevenueManagerPersons)
  }

  const handleInputChange = (value: string) => {
    const idsOnlyArray = selectedPropertyPersons.map((property) => property.id)
    const propertyIdsString = idsOnlyArray.join(',') // Join the IDs with commas
    const idsOnlyRevenueArray = selectedRevenueManagerPersons.map(
      (property) => property.id
    )
    const revenueIdsString = idsOnlyRevenueArray.join(',') // Join the IDs with commas
    setSearchValue(value)
    dispatch(
      getBooking({
        search: value,
        ravenue_manager_id: revenueIdsString,
        propertyId: propertyIdsString,
      })
    )
  }

  const filterOptions = [
    {
      label: 'Property Name',
      persons: propertyListData,
      background: '#f35f0b3b',
      color: '#fff',
      border: '1px solid #F35F0B',
      onSelectPersons: handleSelectPropertyPersons,
      selectedPersons: selectedPropertyPersons,
      setSelectedPersons: setSelectedPropertyPersons,
    },
    {
      label: 'Revenue Manager',
      persons: revenueManagerList,
      background: '#f0b3ea',
      color: '#fff',
      border: '1px solid #f0b3ea',
      onSelectPersons: handleSelectRevenueManagerPersons,
      selectedPersons: selectedRevenueManagerPersons,
      setSelectedPersons: setSelectedRevenueManagerPersons,
    },
  ]

  useEffect(() => {}, [])

  console.log('selectedPropertyPersons', selectedPropertyPersons)
  const columns = [
    {
      accessor: `id`,
      aggregate: 'id',
      Aggregated: ({ cell: { value } }: CellProps<UserI>) => `${value}`,
      Header: 'id'.toUpperCase(),
    },
    {
      Header: 'Guest Name',
      accessor: 'GuestUser.User.name',
      aggregate: 'GuestUser.User.name',
      Aggregated: ({ cell: { value } }: CellProps<UserI>) => `${value}`,
      Cell: (row: any) => (
        <span
          style={{
            textDecoration: 'underline',
            color: '#1976d2',
            cursor: 'pointer',
          }}
          onClick={() => handleBookingDetails(row.row.original.id)} // Add your onClick method here
        >
          {`${row.row.original?.GuestUser?.User?.name}`}
        </span>
      ),
    },
    {
      Header: 'Property Id',
      accessor: 'Property.id',
      aggregate: 'Property.id',
      Aggregated: ({ cell: { value } }: CellProps<UserI>) => `${value}`,
    },
    {
      Header: 'Property Name',
      accessor: 'Property.name',
      aggregate: 'Property.name',
      Aggregated: ({ cell: { value } }: CellProps<UserI>) => `${value}`,
      Cell: (row: any) => handleRedirectToProperty(row.row.original?.Property),
    },
    {
      accessor: `ota_booking_code`,
      aggregate: 'ota_booking_code',
      Aggregated: ({ cell: { value } }: CellProps<UserI>) => `${value}`,
      Header: 'Booking Code',
    },
    {
      Header: 'RevenueManager',
      accessor: 'RevenueManager.User.name',
      Cell: ({ cell }: CellProps<UserI>) => {
        const value = cell.value || 'N/A'
        return <span>{value}</span>
      },
    },
    {
      accessor: 'booking_at',
      aggregate: 'booking_at',
      Header: 'Booking Date',
      Cell: ({ value }: { value: Date }) => {
        const date = moment(value)
        const formattedDate = date.format(
          `Do MMM${moment().year() === date.year() ? '' : "'YY"}`
        )
        return (
          <span title={date.format('DD-MM-YYYY hh:mm A')}>
            {' '}
            {formattedDate === 'Invalid date' ? 'N/A' : formattedDate}
          </span>
        )
      },
    },
    {
      accessor: 'checkin_at',
      aggregate: 'checkin_at',
      Header: 'Checkin Date',
      Cell: ({ value }: { value: Date }) => {
        const date = moment(value)
        const formattedDate = date.format(
          `Do MMM${moment().year() === date.year() ? '' : "'YY"}`
        )
        return (
          <span title={date.format('DD-MM-YYYY hh:mm A')}>
            {formattedDate === 'Invalid date' ? 'N/A' : formattedDate}
          </span>
        )
      },
    },
    {
      accessor: 'checkout_at',
      aggregate: 'checkout_at',
      Header: 'Checkout Date',
      Cell: ({ value }: { value: Date }) => {
        const date = moment(value)
        const formattedDate = date.format(
          `Do MMM${moment().year() === date.year() ? '' : "'YY"}`
        )
        return (
          <span title={date.format('DD-MM-YYYY hh:mm A')}>
            {' '}
            {formattedDate === 'Invalid date' ? 'N/A' : formattedDate}
          </span>
        )
      },
    },
    {
      accessor: 'CouponRedemption[0].Coupons.Offer.name',
      Header: 'Offer',
      Cell: ({ cell }: CellProps<UserI>) => {
        const value = cell.value || 'N/A'
        return <span>{value}</span>
      },
      width: 200,
    },
    {
      id: 'action',
      disableSortBy: true,
      disableFilters: true,
      Cell: (row: any) => (
        <Box sx={{ position: 'relative' }}>
          <Tooltip title='edit'>
            <MoreHorizIcon
              onClick={() => {
                handleToggleModal(row?.row?.original?.id)
              }}
            />
          </Tooltip>
          {isOpen && selectedBookingId === row?.row?.original?.id && (
            <Box
              ref={modalRef}
              sx={{
                position: 'absolute',
                top: 0,
                left: '100%',
                transform: 'translateX(8px)',
                width: 200,
                height: 50,
                bgcolor: 'background.paper',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                p: 1,
                borderRadius: 4,
                zIndex: 1,
              }}
            >
              <Button
                variant='text'
                startIcon={<EditIcon />}
                onClick={() => editBooking(row?.row?.original?.id)}
                sx={{ mb: 1 }}
              >
                Edit
              </Button>
            </Box>
          )}
        </Box>
      ),
    },
  ] as any[]
  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <Header
        spreadSheetUrl={
          'https://docs.google.com/spreadsheets/d/1krvfPKM_E_o4PdNxvDQd-A7BgSASLcV7gpuC_JiZEbs/edit#gid=0'
        }
        title={'Booking Google Sheet'}
      />
      <Box sx={{ display: 'flex', marginTop: '15px', gap: 4 }}>
        <Title name={'Bookings'} fontWeight={'bold'} fontSize={'20px'} />

        <SearchInput
          placeholder='Search by Guest Name/ Booking Code'
          width={400}
          handleInputChange={handleInputChange}
        />
      </Box>
      <Box
        sx={{ display: 'flex', marginTop: '20px', gap: 2, minWidth: 'inherit' }}
      >
        <Title
          name={'Filters'}
          fontWeight={''}
          fontSize={'15px'}
          lineHeight={'70px'}
        />
        {/* <SingleInputDateRangePicker />
        <SingleInputDateRangePicker /> */}
        {filterOptions.map((option) => (
          <MultipleSelect
            key={option.label}
            label={option.label}
            width={180}
            background={option.background}
            color={option.color}
            border={option.border}
            onSelectPersons={option.onSelectPersons}
            selectedPersons={option.selectedPersons}
            setSelectedPersons={option.setSelectedPersons}
            persons={option.persons}
            selected={option.selectedPersons}
          />
        ))}
      </Box>
      <SelectedPersonsChips
        selectedPersons={selectedPropertyPersons.concat(
          selectedRevenueManagerPersons
        )}
        selectedPropertyPersons={selectedPropertyPersons}
        selectedRevenueManagerPersons={selectedRevenueManagerPersons}
        onDelete={handleDelete}
      />

      <Page>
        <CssBaseline />
        <Table
          name={'testTable'}
          columns={columns}
          data={data}
          onAdd={() => addBookings('add')}
          btnName={'Add Booking'}
          downloadCsv={() => downloadToCsv()}
        />
      </Page>
    </>
  )
}

export default Bookings
