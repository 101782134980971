import Input from '../../components/Input'
import {
  Box,
  Button,
  Container,
  Grid,
  CssBaseline,
  createTheme,
  ThemeProvider,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, ChangeEvent } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '@/redux/store'
import {
  createBooking,
  getBookingId,
  editBooking,
  lifeCycleStages,
  keyAccountManager,
  getChannelList,
  getProperties,
  getUsers,
} from '../../redux/features/BookingSlice'
import styles from '../user/user.module.scss'
import Dropdown from '../../components/Dropdown'
import { ToastContainer } from 'react-toastify'
import BreadcrumbsComponent from '../../components/Breadcrums/Breadcrums'
import Calendar from '../../components/Calender'
import dayjs, { Dayjs } from 'dayjs'
import moment from 'moment'
import generateConfig, { Config } from '../../components/HelmetConfig'
import { Helmet } from 'react-helmet'

const inputFields = {
  guestCount: '',
  roomNightsCount: '',
  bookingCode: '',
}

export const bookingTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
          // backgroundColor:'yellow'
        },
      },
    },
  },
})

const theme = createTheme()

interface MultiselectState {
  [key: string]: string[]
}

interface DateField {
  name: string
  date: Date | null
}
type OptionType = {
  firstName: string;
  label: string;
  name: string;
  id: string;
};
export default function PropertiesForm() {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const isModalOpen = useSelector((state: any) => state?.book?.isModalOpen)
  const newDetailsId = useSelector((state: any) => state?.book?.bookingId)
  const stages: any = useSelector((state: any) => state?.book?.lifeCycleStage)
  const accountManager: any = useSelector(
    (state: any) => state?.book?.accountManager
  )
  const propertyList = useSelector(
    (state: any) => state?.book?.propertiesGettingState
  )
  const userList = useSelector((state: any) => state?.book?.usersGettingState)
  const channelList: any = useSelector(
    (state: any) => state?.book?.getChannelList
  )
  const [fields, setInputFields] = useState<any>(inputFields)
  const [images, setImages] = useState<File[]>([])
  const [formData, setFormData] = useState({
    user: '',
    stageIndex: '',
    property: '',
    keyAccountMana: '',
    channel: '',
  })
  const [addAressSectionOpen, setAddressesSectionOpen] = useState(false)

  const [checkInDate, setCheckInDate] = useState<any>(null)
  const [checkOutDate, setCheckOutDate] = useState<any>(null)
  const [bookingOutDate, setBookingOutDate] = useState<any>(null)

  //Errors
  const [errors, setErrors] = useState<any>({})
  const [pageTitle, setPageTitle] = useState('Add Guest')
  const config: Config = generateConfig(pageTitle)

  const handleBookingSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let bookingId = location?.pathname.split('/').filter(Boolean).pop()
    if (handleValidation()) {
      let bookingData = {
        property_id: formData.property,
        guest_user_id: formData.user,
        guests_count: fields.guestCount,
        channel_id: formData.channel,
        room_nights_count: fields.roomNightsCount,
        ota_booking_code: fields.bookingCode,
        checkin_at: checkInDate
          ? moment(checkInDate.toISOString()).format('YYYY-MM-DD')
          : null,
        checkout_at: checkOutDate
          ? moment(checkOutDate.toISOString()).format('YYYY-MM-DD')
          : null,
        booking_at: bookingOutDate
          ? moment(bookingOutDate.toISOString()).format('YYYY-MM-DD')
          : null,
        lifecycle_stage_id: formData.stageIndex,
        revenue_manager_id: formData.keyAccountMana,
      }

      if (
        bookingId === 'add' &&
        !JSON.parse(sessionStorage.getItem('BookingId') || 'null')
      ) {
        dispatch(createBooking(bookingData)).then((response) => {
          const id = JSON.parse(sessionStorage.getItem('BookingId') || 'null')
          if (response.payload === true) {
            navigate(`/bookings/${id}/details`)
          }
        })
      } else {
        let editId = {
          id:
            JSON.parse(sessionStorage.getItem('BookingId') || 'null') ||
            location?.pathname.split('/').filter(Boolean).pop(),
        }
        bookingData = Object.assign({}, bookingData, editId)
        dispatch(editBooking(bookingData)).then((response) => {
          if (
            response?.type === 'editBooking/fulfilled' &&
            response.payload === true
          ) {
            navigate(`/bookings/${bookingId}/details`)
          }
        })
      }
    }
  }

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.persist()
    const { name, value } = e.currentTarget
    if (name === 'pincode' && value.length > 6) {
      return
    }
    setInputFields((prevFields: any) => ({
      ...prevFields,
      [name]: value,
    }))
    setErrors((prevErrors: any) => {
      const { [name]: removedField, ...updatedError } = prevErrors
      return updatedError
    })
  }

  useEffect(() => {
    dispatch(lifeCycleStages())
    dispatch(keyAccountManager())
    dispatch(getProperties())
    dispatch(getUsers())
    dispatch(getChannelList())
  }, [])

  useEffect(() => {
    const bookingAddFormData = location?.pathname
      .split('/')
      .filter(Boolean)
      .pop()
    const bookingId =
      bookingAddFormData || newDetailsId || sessionStorage.getItem('BookingId')

    if (bookingId !== 'add') {
      dispatch(getBookingId(bookingId)).then((response) => {
        if (response?.type === 'getBookingId/fulfilled') {
          const booking = response.payload
          const {
            room_nights_count,
            Property,
            GuestUser,
            RevenueManager,
            LifeCycleStage,
            booking_at,
            guests_count,
            checkin_at,
            checkout_at,
            Channel,
            ota_booking_code,
          } = booking || {}

          setInputFields({
            roomNightsCount: room_nights_count || 'N/A',
            guestCount: guests_count || 'N/A',
            bookingCode: ota_booking_code || 'N/A',
          })

          setFormData((prevFormData) => ({
            ...prevFormData,
            stageIndex: LifeCycleStage?.id || 'N/A',
            property: Property?.id || 'N/A',
            keyAccountMana: RevenueManager?.User?.id || 'N/A',
            user: GuestUser?.User?.id || 'N/A',
            channel: Channel?.id || 'N/A',
          }))

          setCheckInDate(dayjs(checkin_at))
          setCheckOutDate(dayjs(checkout_at))
          setBookingOutDate(dayjs(booking_at))
        }
      })
    }
  }, [isModalOpen, location])

  const handleValidation = () => {
    const requiredFields: string[] = ['guestCount', 'roomNightsCount']
    const fieldLabels: Record<string, string> = {
      guestCount: 'Guest count',
      roomNightsCount: 'Room Night',
      property: 'Primary Owner',
      keyAccountMana: 'Key Account Manager',
      stageIndex: 'Onboarding Stage',
      user: 'Guest',
    }
    const requiredData: string[] = [
      'stageIndex',
      'property',
      'keyAccountMana',
      'user',
    ]
    const errors: Record<string, string> = {}
    let formIsValid = true

    requiredFields.forEach((field) => {
      if (!fields[field]) {
        formIsValid = false
        errors[field] = `${fieldLabels[field]} Field is Required!`
      }
    })

    requiredData.forEach((data) => {
      if (!(formData as Record<string, string>)[data]) {
        formIsValid = false
        errors[data] = `${fieldLabels[data]} Field is Required!`
      }
    })

    // if (!checkInDate) {
    //   formIsValid = false
    //   errors.checkInDate = 'Check-In Date is Required!'
    // }

    // if (!checkOutDate) {
    //   formIsValid = false
    //   errors.checkOutDate = 'Check-Out Date is Required!'
    // }

    if (!bookingOutDate) {
      formIsValid = false
      errors.bookingOutDate = 'Booking-Out Date is Required!'
    }

    setErrors(errors)
    return formIsValid
  }

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptionType | null, name: string) => {
    const value = newValue ? newValue.id : null;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the specific error field for the selected dropdown
    }));
  };

  const handleDateChange = (date: Date | null, name: string) => {
    if (name === 'checkInDate') {
      setCheckInDate(date)
    } else if (name === 'checkOutDate') {
      setCheckOutDate(date)
      if (date && date >= checkInDate) {
        setCheckOutDate(date)
      }
    } else if (name === 'bookingOutDate') {
      setBookingOutDate(date)
    }
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: '',
    }))
  }

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{config.title}</title>
      </Helmet>
      <BreadcrumbsComponent />
      <ThemeProvider theme={bookingTheme}>
        <Container disableGutters component='main' maxWidth='xl' sx={{ mt: 4 }}>
          <CssBaseline />
          <Typography component='h1' variant='h5'>
            Basic Details
          </Typography>
          <Box
            component='form'
            onSubmit={handleBookingSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={3}>
                <Dropdown
                  margin={0}
                  label={'Select Channel'}
                  options={channelList}
                  onChange={(event, newValue) => handleChange(event, newValue, 'channel')}
                  name='channel'
                  value={formData.channel}
                  errormsg={errors?.channel}
                  error={errors?.channel ? true : false}
                  className={`${styles.login_dropdown_container}`}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  margin='normal'
                  label='OTA Booking Code'
                  id='name'
                  name='bookingCode'
                  autoComplete=''
                  onChange={handleInputChange}
                  autoFocus
                  required
                  type='text'
                  disabled={false}
                  errorMsg={errors?.bookingCode}
                  error={errors?.bookingCode ? true : false}
                  value={fields?.bookingCode}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  margin={0}
                  label={'Select Property'}
                  options={propertyList}
                  onChange={(event, newValue) => handleChange(event, newValue, 'property')}
                  name='property'
                  value={formData.property}
                  errormsg={errors?.property}
                  error={errors?.property ? true : false}
                  className={`${styles.login_dropdown_container}`}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  margin={0}
                  label={'Select Guest'}
                  options={userList?.data?.pms_users}
                  onChange={(event, newValue) => handleChange(event, newValue, 'user')}
                  name='user'
                  value={formData.user}
                  errormsg={errors?.user}
                  error={errors?.user ? true : false}
                  className={`${styles.login_dropdown_container}`}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  margin={0}
                  label={'Select Revenue Manager'}
                  options={accountManager}
                  onChange={(event, newValue) => handleChange(event, newValue, 'keyAccountMana')}
                  name='keyAccountMana'
                  value={formData.keyAccountMana}
                  errormsg={errors?.keyAccountMana}
                  error={errors?.keyAccountMana ? true : false}
                  className={`${styles.login_dropdown_container}`}
                />
              </Grid>
              <Grid item xs={3}>
                <Dropdown
                  margin={0}
                  label={'Select LifeCycle Stage'}
                  options={stages}
                  onChange={(event, newValue) => handleChange(event, newValue, 'stageIndex')}
                  name='stageIndex'
                  value={formData.stageIndex}
                  errormsg={errors?.stageIndex}
                  error={errors?.stageIndex ? true : false}
                  className={`${styles.login_dropdown_container}`}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  margin='normal'
                  label='Guests Count'
                  id='name'
                  name='guestCount'
                  autoComplete=''
                  onChange={handleInputChange}
                  autoFocus
                  required
                  type='number'
                  disabled={false}
                  errorMsg={errors?.guestCount}
                  error={errors?.guestCount ? true : false}
                  value={fields?.guestCount}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  margin='normal'
                  onChange={handleInputChange}
                  required
                  name='roomNightsCount'
                  label='Room Nights Count'
                  type='number'
                  id='roomNightsCount'
                  autoComplete=''
                  disabled={false}
                  errorMsg={errors?.roomNightsCount}
                  error={errors?.roomNightsCount ? true : false}
                  value={fields?.roomNightsCount}
                />
              </Grid>

              <Grid item xs={3}>
                <Calendar
                  selectedDate={bookingOutDate}
                  onDateChange={(date) =>
                    handleDateChange(date, 'bookingOutDate')
                  }
                  label={'Booking Date'}
                  // errormsg={errors?.bookingOutDate}
                  // error={errors?.bookingOutDate ? true : false}
                />
              </Grid>
              <Grid item xs={3}>
                <Calendar
                  selectedDate={checkInDate}
                  onDateChange={(date) => handleDateChange(date, 'checkInDate')}
                  label={'CheckIn'}
                  // errormsg={errors?.checkInDate}
                  // error={errors?.checkInDate ? true : false}
                />
              </Grid>
              <Grid item xs={3}>
                <Calendar
                  selectedDate={checkOutDate}
                  onDateChange={(date) =>
                    handleDateChange(date, 'checkOutDate')
                  }
                  label={'CheckOut'}
                  // errormsg={errors?.checkOutDate}
                  // error={errors?.checkOutDate ? true : false}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2, width: 150 }}
              >
                Submit
              </Button>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  )
}
