import React from 'react'
import { RouteType } from './config'

import HotelIcon from '@mui/icons-material/Hotel'
import VillaIcon from '@mui/icons-material/Villa'
import SettingsIcon from '@mui/icons-material/Settings'

import HomePage from '../pages/home/HomePage'
import BookingForm from '../pages/Bookings/BookingsForm'
import Booking from '../pages/Bookings/Bookings'
import BookingDetails from '../pages/Bookings/BookingsDetails'
import ConfigForm from '../pages/config/ConfigForm'
import ConfigListing from '../pages/config/ConfigListing'
import PropertyListing from '../pages/Properties/Property'
import PropertyDetails from '../pages/Properties/PropertyDetails'

const appRoutes: RouteType[] = [
  {
    path: '/',
    element: <HomePage />,
    state: 'home',
  },
  {
    path: '/properties',
    element: <PropertyListing />,
    state: 'component.properties',
    sidebarProps: {
      displayText: 'Properties',
      icon: <VillaIcon />,
    },
  },
  {
    path: '/property/:id/details',
    element: <PropertyDetails />,
    state: 'propertyDetails',
  },
  {
    path: '/bookings',
    element: <Booking />,
    state: 'component.bookings',
    sidebarProps: {
      displayText: 'Bookings',
      icon: <HotelIcon />,
    },
  },
  {
    path: '/configs/:id',
    element: <ConfigForm />,
    state: 'component.configs',
  },
  {
    path: '/configs',
    element: <ConfigListing />,
    state: 'configs.listing',
    sidebarProps: {
      displayText: 'Product Configs',
      icon: <SettingsIcon />,
    },
  },
  {
    path: `/bookings/:id`,
    element: <BookingForm />,
    state: 'bookingsAdd',
  },
  {
    path: '/bookings/:id/details',
    element: <BookingDetails />,
    state: 'bookingsDetails',
  },
]

export default appRoutes
