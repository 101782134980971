import { ListItemButton, ListItemIcon } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import colorConfigs from '../../configs/colorConfigs'
import { RootState } from '../../redux/store'
import { RouteType } from '../../routes/config'
import React from 'react'

type Props = {
  item: RouteType
}

const SidebarItem = ({ item }: Props) => {
  const { userState } = useSelector((state: RootState) => state.users)

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      sx={{
        '&: hover': {
          backgroundColor: colorConfigs.sidebar.hoverBg,
        },
        backgroundColor:
          userState === item.state ? colorConfigs.sidebar.activeBg : 'unset',
      }}
    >
      <ListItemIcon
        sx={{
          color: colorConfigs.sidebar.color,
          minWidth: '40px',
        }}
      >
        {item.sidebarProps.icon && item.sidebarProps.icon}
      </ListItemIcon>
      {item.sidebarProps.displayText}
    </ListItemButton>
  ) : null
}

export default SidebarItem
