import React, { useCallback } from 'react'
import debounce from 'lodash/debounce'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

interface SearchInputProps {
  placeholder?: string
  width?: string | number
  handleInputChange?: (value: string) => void
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = 'Search',
  width = 400,
  handleInputChange,
}) => {
  // Create a debounced version of the handleInputChange function
  const debouncedHandleInputChange = useCallback(
    debounce((value: string) => {
      handleInputChange?.(value)
    }, 700), // Adjust the debounce delay as needed
    []
  )

  // Handle input change directly with debouncing
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    debouncedHandleInputChange(inputValue)
  }

  return (
    <Paper
      component='form'
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: width,
        height: '45px',
      }}
    >
      <IconButton disabled sx={{ p: '10px' }} aria-label='menu'>
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
      />
    </Paper>
  )
}

export default SearchInput
