import React from 'react'
import { Box } from '@mui/material'

interface TitleProps {
  name: string
  fontWeight: string
  fontSize: string
  lineHeight: string
}

const Title: React.FC<TitleProps> = ({
  name,
  fontWeight,
  fontSize,
  lineHeight,
}) => {
  return (
    <Box sx={{ fontWeight: fontWeight, fontSize: fontSize, lineHeight }}>
      {name}
    </Box>
  )
}

export default Title
